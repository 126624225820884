<template>
  <div class="box">
    <div style="height: 100vh;overflow: scroll;">
      <div style="padding: 1rem 0 0.2rem 1.3rem;color: white;">
        {{ getStatus() }}
      </div>
      <div class=" bgc" style="padding: 1rem;">
        <!-- 循环商品信息 -->
        <div style="display: flex;flex-direction: column; ">

          <div style="font-weight: 600; font-size: 1rem;">{{ movieData?.cinemaName }} </div>
          <div style="padding-top: 0.5rem;padding-bottom: 1rem; font-size: 0.85rem;">{{
          movieData?.cinemaAddress }} </div>
          <div class="flex">
            <div>
              <img :src="movieData?.moviePicUrl" alt="" class="img" />
            </div>
            <div class="p-lr-10" style="font-size: 1rem;">
              <span style=" font-weight: 600;"> {{ movieData?.movieName }}</span>
              <p style="color: #666;font-size: 0.85rem;margin-top: 0.5rem;"> {{ movieData?.hallType }}</p>
              <div style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 0.5rem;">
                <span style="color: #666;font-size: 0.85rem;"> {{ movieData?.moviePlayTime }}</span>
              </div>
              <span style="color: #666;font-size: 0.85rem; margin-top: 0.3rem;"> {{ movieData?.hallName }}</span>
              <div>
                <span style="font-size: 0.85rem;margin-top: 0.3rem;">{{ movieData?.seatNames }}</span>
              </div>
            </div>
            
          </div>
          <!-- <div class="price">
          <div>
            <span style="font-size: 0.7rem;">￥</span>
            <span style="font-size: 1rem;font-weight: 600;">{{ item1.present_price }}</span>
          </div>
          <div class="t-a-r p-5 font-s-12">共{{ item1.count }}张</div>
        </div> -->
        </div>

        <!-- 收货地址信息 -->
        <!-- <div class="t-a-r p-t-10 msg">
        <div>运费：￥{{ freight }}</div>
        <div style="margin-top: 0.2rem;">
          共计：￥{{
            mallPrice
          }}
        </div>
      </div> -->
      </div>
      <!--  -->
      <div v-if="this.Paystatus == '6'" class="bgc" style="display: flex;flex-direction: column;align-items: center;">
        <van-image class="tickets" :src="ticketsback"> </van-image>
        <span style="font-size: 1.1rem; margin-top: 1rem;"> 正在出票...</span>
        <span style="font-size: 1rem; color: #666; margin: 1rem 2rem;"> 预计1-15分钟内出票，若遇到影院异常导致出票失败，系统将为您自动退款</span>
      </div>
      <div v-if="this.movieData?.ticketList" class="bgc" style="padding: 0.5rem 0 0.5rem;">
        <div style="font-size: 1.2rem;font-weight: 600; margin: 0.5rem;">取电影票</div>
        <div style="width: 100%;align-items: center; display: flex;flex-direction: column;justify-content: center;">
          <van-image style="width: 12rem; height: 12rem;" lazy-load
            :src="this.movieData?.ticketList[0].qrCode"></van-image>
          <span style="color: #666;font-size: 0.9rem;"> {{ this.movieData?.number }}张电影票</span>
          <div style="margin-top: 0.2rem;font-weight: 600; font-size: 1rem;">
            <span v-for="(item, index) in this.movieData?.ticketList[0].code">
              {{ item.text }}:{{ item.value }}
            </span>
          </div>
        </div>

      </div>
      <div class="bgc" style="padding: 0.5rem ;margin-bottom: 3rem;">
        <div style="font-size: 1.2rem;font-weight: 600; margin: 0.5rem;">订单信息</div>
        <div style="display: flex;  justify-content: space-between;font-size: 1rem;">
          <span style=" margin: 0.5rem; color: #888;">订单编号</span>
          <span style=" margin: 0.5rem; ">{{ orderNo }}</span>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: space-between;font-size: 1rem; ">
          <span style="margin: 0.5rem; color: #888;">下单时间</span>
          <span style=" margin: 0.5rem; ">{{ add_time }}</span>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: space-between; font-size: 1rem;">
          <span style=" margin: 0.5rem; color: #888;">手机号</span>
          <span style=" margin: 0.5rem; ">{{ getPhone() }}</span>
        </div>
        <div v-if="movieDetail?.consumptionType" @click="showPayDetail"
          style="display: flex; flex-direction: row; justify-content: space-between; font-size: 1rem;">
          <span style=" margin: 0.5rem; color: #888;">支付方式</span>
          <span style=" margin: 0.5rem; ">{{ getPayType() }} <van-icon name="arrow" /></span>

        </div>
        <!-- <div v-if="Paystatus != '0'" style="display: flex;  justify-content: space-between;font-size: 1rem;">
        <span style=" margin: 0.5rem; color: #888;">实付款</span>
        <span v-if="sfOrderNo" style=" margin: 0.5rem; color: #D23B2E;font-weight: 600;"><span
            style="font-size: 0.9rem;color: #D23B2E;">
            ￥</span>{{ cakaData?.total_amount }}</span>
        <span v-else style=" margin: 0.5rem; color: #D23B2E;font-weight: 600;"><span
            style="font-size: 0.9rem;color: #D23B2E;"> ￥</span>{{
              getTotlePrice() }}</span>
      </div> -->
      </div>
    </div>
    <div v-if="Paystatus == '0'" class="bottom">
      <div class="price">
        <span style="font-size: 0.9rem;margin-top: 0.1rem;font-weight: 500;">需支付：</span>

        <div v-if="Point" style="text-align: end; ">
          <span style="font-size: 1.1rem;font-weight: 600;">{{ Point }}</span>
          <span style="font-size: 0.9rem;">点</span>
        </div>
      </div>
      <div class="flex">
        <div style="border: 1px solid #aea9a9; ; border-radius: 1rem; padding: 0.2rem 0.8rem;  "
          @click.stop="showDialog">
          取消订单
        </div>
        <div
          style="border: 1px solid #D23B2E; ; border-radius: 1rem; padding: 0.2rem 0.8rem; color: #D23B2E;margin: 0.5rem 1rem 0.5rem 0.5rem; "
          @click.stop="changePay">
          去支付
        </div>
      </div>
    </div>
    <PayList v-if="showPL" :pay-list="payList" @closed="closedPay">
    </PayList>
  </div>
</template>

<script>
import PayList from "../../components/order/PayList.vue";
export default {
  name: "",

  data() {
    return {
      ticketsback: require("@/assets/images/issue_tickets.png"),
      add_time: "",
      form: {
        orderId: '',
        qmmOrderId: '',
      },
      phone: '',
      movieData: null,
      movieDetail: null,
      Paystatus: '',
      userId: '',
      weiPrice: null,
      orderNo: '',
      Point: null,
      showPL: false,
      payList: [],
    };
  },
  components: { PayList },
  methods: {
    showPayDetail() {
      if (this.payList && this.payList.length > 0) {
        this.showPL = true
      }
    },
    closedPay(val) {
      this.showPL = val
    },
    getPayType() {
      if (this.movieDetail?.consumptionType == '0') {
        return '卡余额支付'
      } else if (this.movieDetail?.consumptionType == '1') {
        return '在线支付'
      } else if (this.movieDetail?.consumptionType == '2') {
        return '卡余额支付+微信支付'
      }
    },
    goLogistics(val) {
      this.$router.push({
        path: "/Logistics",
        query: {
          id: val,
        },
      });
    },
    toPay() {
      localStorage.setItem('film_id', this.movieDetail.productId)
      let seatList = []
      this.movieDetail.productSubOrderList.forEach(element => {
        let seat = {
          seatId: element.seatId,
          price: element.showPrice
        }
        seatList.push(seat)

      });
      localStorage.setItem("seatList", JSON.stringify(seatList))
      localStorage.setItem("sale_price", this.movieDetail.originalPrice)
      let entrCode = localStorage.getItem('entrCode')
      if (entrCode == 'E3') {
        this.$router.push({
          path: "/MovieCashier",
          query: {
            cardId: this.movieData.cardId,
            order: this.form.qmmOrderId,
            creatTime: this.movieData.createTime
          },
        });
      } else
        this.$router.push({
          path: "/Cashier",
          query: {
            cardId: this.movieData.cardId,
            order: this.form.qmmOrderId,
            creatTime: this.movieData.createTime
          },
        });
    },
    showDialog() {
      this.$Dialog.confirm({
        title: "提示",
        message: "是否确认取消订单？",
        confirmButtonText: '我在想想',
        cancelButtonText: '取消订单'
      })
        .then(() => {
        })
        .catch(() => {
          // on cancel
          this.cancelPayment()
        });
    },
    cancelPayment() {
      this.$api
        .cancelPayment(this.form.qmmOrderId)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            this.$Toast(res.msg);
            this.$router.back();
          }

        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },

    directWeChat(params) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": params.appId,     //公众号ID，由商户传入     
        "timeStamp": params.timeStamp,        //时间戳，自1970年以来的秒数     
        "nonceStr": params.nonceStr,//随机串     
        "package": params.package,
        "signType": params.signType,       //微信签名方式：     
        "paySign": params.paySign, //微信签名 
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$Toast('支付成功');
            this.$router.back();
          }
        });
    },
    init(params) {
      console.log('params', params)
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.directWeChat(params), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.directWeChat(params))
          document.attachEvent('onWeixinJSBridgeReady', this.directWeChat(params))
        }
      } else {
        this.directWeChat(params)
      }
    },
    changePay() {
      // if (this.weiPrice) {
      //   this.weiPay()
      // } else {

      this.toPay()
      // }
    },
    weiPay() {
      let form = {
        amount: this.weiPrice,
        userId: this.userId,
        channelOrderNo: this.form.qmmOrderId
      }
      this.$api
        .generatePayOrderInfo(form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            this.init(res.data)
          }

        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    getOrderDetail() {
      this.$api
        .queryMovieTicketDetail(this.form.orderId, this.form.qmmOrderId)
        .then((res) => {
          // console.log(this.list);
          if (res.code == 1) {
            this.$Toast(res.code);
          } else {
            if (res.data) {
              this.movieDetail = res.data
              this.payList = this.movieDetail.spendLogList
              this.movieData = JSON.parse(res.data.orderDetail)
              this.add_time = res.data.createTime
              this.Point = this.movieDetail.originalPrice
            }
          }
        })
        .catch((err) => {
          // this.$Toast(err);
        });
    },

    getPhone() {
      return this.$utils.formPhone(this.phone);
    },
    getStatus() {
      if (this.Paystatus == '0') {
        return '待支付'
      } else if (this.Paystatus == '1') {
        return '待发货'
      } else if (this.Paystatus == '2') {
        return '已发货'
      } else if (this.Paystatus == '3') {
        return '已完成'
      } else if (this.Paystatus == '4') {
        return '已取消'
      } else if (this.Paystatus == '5') {
        return '已超时'
      } else if (this.Paystatus == '6') {
        return '出票中'
      } else if (this.Paystatus == '7') {
        return '出票成功'
      } else if (this.Paystatus == '8') {
        return '出票失败'
      } else if (this.Paystatus == '10') {
        return '待收货'
      } else if (this.Paystatus == '15') {
        return '已关闭'
      }
    }
  },
  mounted() {
    // console.log(this.list);
    this.form.orderId = this.$route.query.orderId
    this.form.qmmOrderId = this.$route.query.qmmOrderId
    this.Paystatus = this.$route.query.status
    this.orderNo = this.$route.query.orderNo

    this.userId = JSON.parse(localStorage.getItem("userInfo")).id
    this.phone = localStorage.getItem("loginphone")
    this.getOrderDetail()
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  background-color: #f5f5f5;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(180deg, #D23B2E, rgba(210, 59, 46, 0.00));
  background-size: 100% 20rem;
  background-repeat: no-repeat;

  .img {
    width: 5.6rem;
    height: 7.5rem;
    border-radius: 0.5rem;
  }

  .bgc {
    background-color: #fff;
    border-radius: 0.8rem;
    margin: 0.5rem 1rem;
  }

  .msg {
    margin-top: 20px;
    padding-top: 5px;
    border-top: 1px solid #ededed;
  }
}

.tickets {
  width: 8rem;
  height: 8rem;
  margin-top: 1rem;
}

.price {
  min-width: 3.5em;
  display: flex;
  margin-left: 1rem;
  flex-direction: row;
  justify-content: end;
  font-weight: 600;
  margin-top: 0.5rem;
  color: #D23B2E;
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-top: 1px solid #ededed;
}
</style>